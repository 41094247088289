import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';

import { useSeo } from '~hooks/useSeo';
import { useLayout } from '~hooks/useLayout';
import { useLocationDetails } from '~hooks/useLocationDetails';

import LocationSearchBar from './location-search-bar';
import LocationContext from './locationContext';

import Header from './header';
import WorkersList from './list';

import './style.scss';

const Workers = () => {
  const intl = useIntl();
  const urlLocation = useLocation();

  const { locationId } = queryString.parse(urlLocation.search) || null;
  const initialSearchLocationId = useRef(locationId);
  const { locationDetails, pricingDetails, isLoading, requestLocationByID, locationTouched } = useLocationDetails(
    locationId,
    [urlLocation.pathname]
  );

  useLayout({
    displayFooter: false,
  });

  useSeo(
    intl.formatMessage({
      id: 'containers.workers.seo.default.title',
    })
  );

  return (
    <>
      <LocationContext.Provider
        value={{
          locationDetails,
          pricingDetails,
          initialSearchLocationId: initialSearchLocationId.current,
          onChangeLocation: requestLocationByID,
          isLoadingLocation: isLoading,
          locationTouched,
        }}
      >
        <section className="c-page l-container c-page--matches">
          <Header />
          {!locationId && (
            <>
              <div className="c-location-intro">
                {intl.formatMessage({
                  id: 'containers.workers.intro',
                  defaultMessage: 'Lets get started! Please write down your address',
                })}
              </div>
              <LocationSearchBar />
            </>
          )}

          {!locationId || (!locationDetails && !isLoading) ? null : <WorkersList />}
        </section>
      </LocationContext.Provider>
    </>
  );
};

export default Workers;
